<template>
  <div class="wrapper-loading-skeleton">
    <div class="loading-skeleton">
      <div class="image bg"></div>
      <div class="main">
        <div class="title bg"></div>
        <div class="description">
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="footer-skeleton">
        <div class="tags">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <div class="show-all bg"></div>
        </div>
        <hr />
        <div class="last-change">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="animation"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkeletonCard",
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.wrapper-loading-skeleton {
  position: relative;
  background-color: $white;
  -webkit-box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  overflow: hidden;
  border-radius: 15px;
  padding: 45px 20px 20px 20px;
  min-height: 500px;
  height: 100%;
  width: 100%;

  background-image: linear-gradient(
    lightgray 30px,
    transparent 0
  ); // card title

  background-repeat: no-repeat;

  background-size: 100% 60px; // card title

  background-position: 0 0; // card title

  .loading-skeleton {
    width: 100%;
    height: 100%;

    .bg,
    span {
      background-color: lightgray;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
    }

    .image {
      width: 100%;
      height: 40%;
      margin-bottom: 15px;
    }

    .main {
      height: 30%;

      .title {
        width: 150px;
        height: 20%;
        margin-bottom: 3%;
      }

      .description {
        height: 75%;
        span {
          display: block;
          width: 100%;
          height: 20%;
          margin-bottom: 2%;
        }
      }
    }

    .footer-skeleton {
      height: 30%;

      .tags {
        height: 40%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        span {
          display: block;
          width: 22%;
          height: 40%;
          margin-bottom: 2%;
        }

        .show-all {
          width: 120px;
          height: 40%;
        }
      }

      .last-change {
        height: 40%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        span {
          width: 40%;
          height: 20%;

          &:first-child {
            width: 30%;
            margin-right: 50%;
          }
        }
      }
    }

    .animation {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      );

      background-repeat: no-repeat;

      background-size: 100px 100%; /* highlight */

      background-position: -30% 0; /* highlight */

      animation: shine 0.8s infinite;
    }
  }
}

@keyframes shine {
  to {
    background-position: 130% 0; /* highlight */
  }
}
</style>
