var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.disabledClass},[(_vm.link)?_c('router-link',{style:(_vm.styleCircleButton),attrs:{"title":_vm.titleAttribute,"to":_vm.link,"target":_vm.linkTarget}},[_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(
        (!_vm.spinner && !_vm.disabled) ||
        (_vm.spinner && !_vm.disabled) ||
        (!_vm.spinner && _vm.disabled)
      ),expression:"\n        (!spinner && !disabled) ||\n        (spinner && !disabled) ||\n        (!spinner && disabled)\n      "}],attrs:{"icon":_vm.icon}}),_c('FadeLoader',{attrs:{"loading":_vm.disabled && _vm.spinner,"color":_vm.color,"margin":_vm.spinnerMargin,"height":_vm.spinnerHeight,"width":_vm.spinnerWidth,"radius":_vm.spinnerRadius}})],1):_c('router-link',{style:(_vm.styleCircleButton),attrs:{"title":_vm.titleAttribute}},[_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(
        (!_vm.spinner && !_vm.disabled) ||
        (_vm.spinner && !_vm.disabled) ||
        (!_vm.spinner && _vm.disabled)
      ),expression:"\n        (!spinner && !disabled) ||\n        (spinner && !disabled) ||\n        (!spinner && disabled)\n      "}],attrs:{"icon":_vm.icon}}),_c('FadeLoader',{attrs:{"loading":_vm.disabled && _vm.spinner,"color":_vm.color,"margin":_vm.spinnerMargin,"height":_vm.spinnerHeight,"width":_vm.spinnerWidth,"radius":_vm.spinnerRadius}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }