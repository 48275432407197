<template>
	<BaseCard class="media-card" padding="0 20px 20px" margin="0" border-radius="15px">

		<router-link class="media-card__permalink" v-bind:to="link"></router-link>

		<div class="media-card__media" v-bind:style="setBackground">
			<div class="media-card__overlay">{{ openLabel }}</div>
		</div>

		<div class="media-card__title">
			<span>{{title}}</span>
		</div>

	</BaseCard>
</template>

<script>
	import BaseCard from "@/components/Cards/BaseCard";

	export default {
		name: "MediaCard",
		components: {
			BaseCard,
		},
		props: {
			title: {
				type: String,
				required: true,
			},
			media:{
				type:String,
				required: true
			},
			link:{
				type:String,
				required: true
			},
			openLabel:{
				type: String,
				default: ''
			},
		},
		computed: {
			setBackground() {
				const video_extensions = ['webm', 'mp4', 'mkv', 'avi', 'mov'];
				const audio_extensions = ['mp3', 'flac', 'aac', 'aiff'];

				const splited_media = this.media.split('.');
				const extension_media = splited_media[splited_media.length - 1];

				if (video_extensions.includes(extension_media)) {
					return {
						backgroundImage: `url(https://cdn.pixabay.com/photo/2015/12/03/01/27/play-1073616_960_720.png)`,
						backgroundSize: '50%'
					}
				}

				else if (audio_extensions.includes(extension_media)) {
					return {
						backgroundImage: `url(https://cdn.pixabay.com/photo/2017/02/27/20/48/headphones-2104207_960_720.png)`,
						backgroundSize: '50%'
					}
				}

				return { backgroundImage: `url(${ this.media })` }
			}
		}
	}
</script>

<style lang="scss" scoped>

	.media-card {
		position: relative;
		height: 100%;
		flex: 1 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		transition: all .3s ease;

		&__permalink {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			display: flex;
			width: 100%;
			z-index: 1;
		}

		&__media {
			padding-bottom: 80%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			margin: 0 -20px 20px;
			position: relative;
			border-radius: 15px;
		}

		&__overlay {
			position: absolute;
			opacity: 0;
			border-radius: 15px;
			background-color: rgba(0, 0, 0, 0.5);
			color: white;
			transition: .3s;
			width: 100%;
			height: 100%;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}


		&__permalink:hover + .media-card__media .media-card__overlay {
			opacity: 1;
		}

		&__title {
			font-size: 1.125rem;
			font-weight: bold;
			word-break: break-word;
		}
	}

</style>