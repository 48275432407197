<template>
    <BaseCard padding="25px">
        <div class="pim-add-panel">
            <h2>
                {{label}}
            </h2>
            <div class="pim-buttons justify-content-center d-flex flex-wrap">
                <div class="pim-button-container d-flex align-items-center flex-column" v-for="button in buttons" :key="button.id">
                    <div v-if="typeof button.enabled === 'undefined' || button.enabled === true">
                        <CircleButtonCS
                            :link="button.link"
                            :icon="button.icon"
                            @click.native="executeAction( button.action )"
                            margin="0px"
                            font_size="inherit"
                            line_height="inherit"
                            :width="button.width || '100%'"
                            :height="button.height || '100%'"
							:style="{ '--box-shadow-color': button.backgroundColor }"
                            :background_color="button.backgroundColor"
						/>
                        <p>{{button.text}}</p>
                    </div>
                </div>
            </div>
        </div>
    </BaseCard>
</template>

<script>
import CircleButtonCS from '../Buttons/CircleButtonCS'
import BaseCard from '../Cards/BaseCard'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

library.add(faPlus)

export default {
    name: 'AddPanel',
    components: {
        CircleButtonCS,
        BaseCard
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        buttons: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
	methods: {
		executeAction( action ) {
			if ( action && typeof action === 'function' ) action();
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.pim-add-panel {
	width: 100%;

	h2 {
		margin-bottom: 15px;
		font-weight: 700;
		font-size: 1.125rem;
		letter-spacing: 0.01em;
		text-align: center;
		color: $gray_text;
		border: 1px solid rgba(0, 0, 0, 0);
	}

	.pim-buttons {
		margin: 0px -15px 0px -15px;

		.pim-button-container {
			margin: 0px 7.5px 0px 7.5px;
		}

		p {
			font-weight: 700;
			font-size: 1rem;
			letter-spacing: 0.03em;
			text-align: center;
			color: $gray_text;
			border: 1px solid rgba(0, 0, 0, 0);
			margin-bottom: 0px;
			margin-top: 5px;
		}
	}

	::v-deep .add-button {
		display: flex;
		justify-content: center;

		a {
			width: 40px;
			height: 40px;

			&:hover {
				opacity: 1;
				box-shadow: 0 0 7px 1px var(--box-shadow-color);
			}
		}
	}

	@media (min-width: 1200px) {

		h2 { margin-bottom: 20px; }
		.pim-buttons {
			p {
				margin-top: 10px;
			}
		}

		::v-deep .add-button {
			a {
				width: 46px;
				height: 46px;
			}
		}
	}

	@media (min-width: 1400px) {

		::v-deep .add-button {
			a {
				width: 56px;
				height: 56px;
			}
		}
	}
}
</style>
