<template>
  <div v-bind:class="disabledClass">
    <router-link
      :title="titleAttribute"
      v-if="link"
      v-bind:style="styleCircleButton"
      :to="link"
      :target="linkTarget"
    >
      <font-awesome-icon
        :icon="icon"
        v-show="
          (!spinner && !disabled) ||
          (spinner && !disabled) ||
          (!spinner && disabled)
        "
      />
      <FadeLoader
        :loading="disabled && spinner"
        :color="color"
        :margin="spinnerMargin"
        :height="spinnerHeight"
        :width="spinnerWidth"
        :radius="spinnerRadius"
      />
    </router-link>
    <router-link
      :title="titleAttribute"
      v-else
      v-bind:style="styleCircleButton"
    >
      <font-awesome-icon
        :icon="icon"
        v-show="
          (!spinner && !disabled) ||
          (spinner && !disabled) ||
          (!spinner && disabled)
        "
      />
      <FadeLoader
        :loading="disabled && spinner"
        :color="color"
        :margin="spinnerMargin"
        :height="spinnerHeight"
        :width="spinnerWidth"
        :radius="spinnerRadius"
      />
    </router-link>
  </div>
</template>

<script>
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
library.add(faTimesCircle);
const colors = require("/colors.config.json");
export default {
  name: "CircleButton",
  components: {
    FadeLoader,
  },
  data() {
    return {
      styleCircleButton: {
        "--color": this.color,
        "--background_color": this.background_color,
        "--width": this.width,
        "--font_size": this.font_size,
        "--margin": this.margin,
        "--padding": this.padding,
        "--height": this.height,
        "--line_height": this.line_height,
      },
    };
  },
  props: {
    color: {
      type: String,
      default: `${colors.white}`,
    },
    background_color: {
      type: String,
      default: "#3998DB",
    },
    link: {
      type: [String, Object],
      default: "",
    },
    font_size: {
      type: String,
      default: "1rem",
    },
    width: {
      type: String,
      default: "36px",
    },
    margin: {
      type: String,
      default: "0",
    },
    padding: {
      type: String,
      default: "0",
    },
    icon: {
      type: String,
      default: "plus",
    },
    height: {
      type: String,
      default: "36px",
    },
    line_height: {
      type: String,
      default: "36px",
    },
    titleAttribute: {
      type: String,
      default: "",
    },
    linkTarget: {
      type: String,
      default: "_self",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    spinner: {
      type: Boolean,
      default: false,
    },
    spinnerRadius: {
      type: String,
      default: "9px",
    },
    spinnerMargin: {
      type: String,
      default: "-3px 0 0 -1px",
    },
    spinnerHeight: {
      type: String,
      default: "6px",
    },
    spinnerWidth: {
      type: String,
      default: "2px",
    },
  },
  computed: {
    disabledClass() {
      if (this.disabled === true) return "add-button disabled";
      return "add-button";
    },
  },
};
</script>

<style lang="scss" scoped>
.add-button.disabled {
  pointer-events: none;
}

.add-button.disabled * {
  background-color: silver !important;
}
.add-button {
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--width);
    height: var(--height);
    line-height: var(--line_height);
    font-size: var(--font_size);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: var(--color);
    margin: var(--margin);
    padding: var(--padding);
    transition: all 0.3s ease;
    background-color: var(--background_color);

    &:hover {
      opacity: 0.7;
      box-shadow: 2px 4px 8px -2px rgba(0, 0, 0, 0.25);
    }
  }
}
</style>
